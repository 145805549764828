import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import "./soulmate.css";
import OtpInput from "react-otp-input";
import leftlogo from "../images/logo_left.png";
import rightlogo from "../images/logo_right.png";
import left_marriagevector from "../images/marriage_image.jpg";
import { useNavigate } from "react-router-dom";

const Soulmatedashboard = () => {
  const baseurl = "https://jitosoulmate.ondemandcrm.co"; // window.location.origin ;
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const [companyDetails, setCompanyDetails] = useState({
    logo_path: "", // Initialize with an empty string or default value
    company_name: "",
    email_address: "",
    address_1: "",
    address_2: "",
    area: "",
    city_name: "",
    state: "",
    country: "",
    pincode: "",
    office_phone1: "",
  });
  const [label, setLabel] = useState({
    contact_label: "",
    product_label: "",
    support_label: "",
  });
  const [showdashboard, setShowdashboard] = useState(false);
  const [showlogin, setShowlogin] = useState(true);
  const [showotp, setShowotp] = useState(false);
  const [showregister, setShowregister] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [continuebtn,setContinuebtn] = useState(false);
  const [countrycode, setCountrycode] = useState([]);
  const [selectedcode, setSelectedcode] = useState("+91");
  const [patientname, setPatientname] = useState("");
  const [regmobileNo, setRegMobileNo] = useState("");
  const [otpbtndisable, setOtpBtnDisable] = useState(false);
  const [otpkey, setOtpkey] = useState();
  const [contactid, setContactid] = useState("");
  const [objecttype, setObjecttype] = useState("");
  const [pinval, setPinVal] = useState("");
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(showotp ? 120 : 0);
  const [contactData, setContactData] = useState({
    contact_id: "",
    contact_type: "",
    contact_name: "",
    contact_email: "",
    mobileno: "",
    contact_category: "",
    add_type: "",
    add_id: "",
    add_nickname: "",
    address_line1: "",
    address_line2: "",
    area: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    billing_flg: "",
  });
  const [add_nickname, setAddnickname] = useState("");
  const [add_address, setAddaddress] = useState("");
  const [add_city, setAddcity] = useState("");
  const [add_area, setAddarea] = useState("");
  const [add_state, setAddstate] = useState("");
  const [add_country, setAddcountry] = useState("");
  const [add_zipcode, setAddzipcode] = useState("");
  const [address, setAddress] = useState([]);
  const [address_id, setAddressID] = useState("");
  const navigate = useNavigate();
  const localStorageValue = localStorage.getItem('showlogin');
  
  //seconds timer
  useEffect(() => {
    setSeconds(showotp ? 120 : 0);
  }, [showotp]);

  useEffect(() => {
    let timer;

    if (seconds > 0) {
      console.log(seconds + "seconds");
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    // Clear the timer when it reaches 0
    if (seconds === 0) {
      clearInterval(timer);
      // You can implement additional logic here, like triggering a resend OTP action
    }
    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, [seconds]);

  const formattedTime = `${Math.floor(seconds / 60)}:${(seconds % 60)
    .toString()
    .padStart(2, "0")}`;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // fetch companylogo and name
  const fetchCompanyDetails = async () => {
    try {
      const res = await axios.post(
        baseurl + `/expo_access_api/companydetails/`,
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
        }
      );
      if (res && res.data) {
        const {
          logo_path,
          company_name,
          email_address,
          address_1,
          address_2,
          area,
          city_name,
          state,
          country,
          pincode,
          office_phone1,
        } = res?.data[0]?.DATA?.Companyinfo;

        const countrycode = res?.data[0]?.DETAILS?.country_isd_master;
        if (typeof countrycode === "object" && countrycode !== null) {
          const arrayOfValues = Object.values(countrycode);
          // const selectecode = arrayOfValues.includes("91") ? "91" : "";
          // setSelectedcode(selectecode)
          setCountrycode(arrayOfValues);
        }

        setCompanyDetails({
          logo_path,
          company_name,
          email_address,
          address_1,
          address_2,
          area,
          city_name,
          state,
          country,
          pincode,
          office_phone1,
        });
        const { contact_label, product_label, support_label } =
          res?.data[0]?.DETAILS;
        setLabel({
          contact_label,
          product_label,
          support_label,
        });
        // localStorage.setItem(
        //   "companyDetails",
        //   JSON.stringify({
        //     logo_path,
        //     company_name,
        //     email_address,
        //     address_1,
        //     address_2,
        //     area,
        //     city_name,
        //     state,
        //     country,
        //     pincode,
        //     office_phone1,
        //   })
        // );
        // localStorage.setItem(
        //   "label".JSON.stringify({
        //     contact_label,
        //     product_label,
        //     support_label,
        //   })
        // );
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  // Company details

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  // for pagerefresh
  useEffect(() => {
    const showlogin = localStorage.getItem("showlogin");
    const showotp = localStorage.getItem("showotp");
    const showdashboard = localStorage.getItem("showdashboard");
    const showregister = localStorage.getItem("showregister");
    const patientname = localStorage.getItem("patientname");


    if (showlogin !== null) {
      setShowlogin(showlogin === "true");
    }
    if (showotp !== null) {
      setShowotp(showotp === "true");
    }
    if (showdashboard !== null) {
      setShowdashboard(showdashboard === "true");
    }
    if (showregister !== null) {
      setShowregister(showregister === "true");
    }
    if (patientname !== null) {
      setPatientname(patientname);
    }
  }, []);

  // for contactid and contacttype

  useEffect(() => {
    const storedContactid = localStorage.getItem("contactid");
    const storedContacttype = localStorage.getItem("contacttype");
    setContactid(storedContactid);
    setObjecttype(storedContacttype);
  }, []);

  //mobileno

  useEffect(() => {
    contact_address(
      localStorage.getItem("contactid"),
      "Y",
      localStorage.getItem("contacttype")
    );
  }, []);

  const handleCheckRecordOtp = (e) => {
    setContinuebtn(true);
    e.stopPropagation();
    e.preventDefault();
    if (mobileNo) {
      axios
        .post(
          baseurl + `/expo_access_api/verifyGenerateOTPByMobileForOrderBot/`,
          {
            AUTHORIZEKEY: AUTHORIZE_KEY,
            contact_mobile: mobileNo,
            country_isd: selectedcode,
          }
        )
        .then((res) => {
          if (res.data[0].STATUS == "SUCCESS") {
            if (res.data[0].lock_for_download != "Y") {
            setOtpkey(res.data[0].DATA);
            setContactid(res.data[0].OBJECT_ID);
            localStorage.setItem("contactid", res.data[0].OBJECT_ID);
            setObjecttype(res.data[0].OBJECT_TYPE);
            localStorage.setItem("contacttype", res.data[0].OBJECT_TYPE);
            setShowlogin(false);
            setShowotp(true);
            setContinuebtn(false);
              // console.log("locak ")
              // toast.error(
              //   "Your Bio-Data download attempts are over hence your account is temporarily locked. Kindly contact system administrator to reactivate your account. !",
              //   {
              //     position: "top-right",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     theme: "colored",
              //   }
              // );
          } else {
              swal("Your Bio-Data download attempts are over hence your account is temporarily locked. Kindly contact system administrator to reactivate your account !");
              /*toast.error(
                "Your Bio-Data download attempts are over hence your account is temporarily locked. Kindly contact system administrator to reactivate your account !",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );*/
              // setShowlogin(true)
            }
            setContinuebtn(false);
          } else {
            setOtpkey("");
          }
        })
        .catch((err) => {
          swal({
            text:"Some thing gone wrong!", // Your error message here
            button: 'OK', // Display an "OK" button
          });
        });
    }
    //handleCheckRecord(e);
  };

  const handleVerifyMobileOtp = async (e) => {
    setOtpBtnDisable(true);
    if (otp) {
      axios
        .post(baseurl + `/expo_access_api/verifyRegisterOTPByMobile/`, {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          key: otpkey,
          verifyOTP: otp,
          contact: mobileNo,
        })
        .then((res) => {
          if (res.data[0].STATUS == "SUCCESS") {
            setShowotp(false);
            if (contactid) {
              getContactdetail(contactid);
              contact_address(
                localStorage.getItem("contactid"),
                "Y",
                localStorage.getItem("contacttype")
              );
              navigate("/soulmatesearch");
              //setShowdashboard(true);
              //localStorage.setItem("showdashboard", true);
              setShowregister(false);
              localStorage.setItem("showregister", false);
              setShowlogin(false);
              localStorage.setItem("showlogin", false);
              setShowotp(false);
              localStorage.setItem("showotp", false);
              setPinVal("");
              setOtpBtnDisable(false);
            } else {
              setShowregister(true);
              setPinVal("");
              setOtpBtnDisable(false);
            }
          } else {
            // Display an error SweetAlert notification
swal({
  text: res.data[0].MSG, // Your error message here
  button: 'OK', // Display an "OK" button
            });

            setOtpBtnDisable(false);
          }
        })
        .catch((err) => {
          swal({
            text: "Some thing gone wrong", // Your error message here
            button: 'OK', // Display an "OK" button
          });
          setOtpBtnDisable(false);
        });
    } else {
      swal({
        text: "Some thing gone wrong", // Your error message here
        button: 'OK', // Display an "OK" button
      });
      setOtpBtnDisable(false);
    }
  };

  async function getContactdetail(id) {
    setOtpBtnDisable(true);
    try {
      let contactId = localStorage.getItem("contactid");

      if (!contactId) {
        contactId = id;
      }
      let contacttype = localStorage.getItem("contacttype");
      const res = await axios.post(
        baseurl + "/ecommerce_api/getRawContactDetails/",
        {
          AUTHORIZEKEY: AUTHORIZE_KEY,
          contact_id: contactId,
          contact_type: contacttype,
        }
      );

      if (res && res.data && res.data[0].DATA) {
        const cnt_id =
          res.data[0].DATA.contact_id > 0 ? res.data[0].DATA.contact_id : "";
        // const cnt_type = res.data[0].DATA.contact_type > 0 ? res.data[0].DATA.Contact.contact_type : "";
        const cnt_email =
          res.data[0].DATA.email_address != ""
            ? res.data[0].DATA.email_address
            : "";
        const cnt_mobile =
          res.data[0].DATA.mobile_no != "" ? res.data[0].DATA.mobile_no : "";
        const cnt_name =
          res.data[0].DATA.name != "" ? res.data[0].DATA.name : "";
        const cnt_addid =
          res.data[0].DATA.address_id > 0 ? res.data[0].DATA.address_id : "";
        const cnt_add1 =
          res.data[0].DATA.address_1 != "" ? res.data[0].DATA.address_1 : "";
        const cnt_add2 =
          res.data[0].DATA.address_2 != "" ? res.data[0].DATA.address_2 : "";
        const cnt_country =
          res.data[0].DATA.country != "" ? res.data[0].DATA.country : "";
        const cnt_state =
          res.data[0].DATA.state != "" ? res.data[0].DATA.state : "";
        const cnt_area =
          res.data[0].DATA.area != "" ? res.data[0].DATA.area : "";
        const cnt_city =
          res.data[0].DATA.city_name != "" ? res.data[0].DATA.city_name : "";
        const cnt_nickname =
          res.data[0].DATA.nickname != "" ? res.data[0].DATA.nickname : "";
        const cnt_zipcode =
          res.data[0].DATA.zipcode != "" ? res.data[0].DATA.zipcode : "";
        setContactData({
          ...contactData,
          contact_id: cnt_id,
          contact_name: cnt_name,
          contact_email: cnt_email,
          mobileno: cnt_mobile,
          add_id: cnt_addid,
          add_nickname: cnt_nickname,
          address_line1: cnt_add1,
          address_line2: cnt_add2,
          area: cnt_area,
          country: cnt_country,
          state: cnt_state,
          city: cnt_city,
          zipcode: cnt_zipcode,
        });
        setPatientname(res.data[0].DATA.name);
        localStorage.setItem("patientname", res.data[0].DATA.name);
        localStorage.setItem("contactData", JSON.stringify(res.data[0].DATA));
      } else {
        swal({
          text: "No contact details found", // Your error message here
          button: 'OK', // Display an "OK" button
        });
      }
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  }

  const onsubmit = async (data) => {
    try {
      const res = await axios.post(baseurl + "/ecommerce_api/saveRawContact/", {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        contact: {
          name: data.name,
          email: data.email,
          mobile: mobileNo,
          country_isd: selectedcode,
        },
      });

      if (res && res.data && res.data[0].DATA) {
        const contactId = res.data[0].DATA;
        const ObjectType = res.data[0].DATA_TYPE;
        // Reset form values
        const fieldsToReset = ["name", "email", "pincode"];
        fieldsToReset.forEach((field) => setValue(field, ""));
        navigate("/soulmatesearch");
        //setShowdashboard(true);
        //localStorage.setItem("showdashboard", true);
        setShowregister(false);
        localStorage.setItem("showregister", false);
        setShowlogin(false);
        localStorage.setItem("showlogin", false);
        setShowotp(false);
        localStorage.setItem("showotp", false);
        setContactid(contactId);
        localStorage.setItem("contactid", contactId);
        setObjecttype(ObjectType);
        localStorage.setItem("contacttype", ObjectType);
        contact_address(
          localStorage.getItem("contactid"),
          "Y",
          localStorage.getItem("contacttype")
        );
        getContactdetail(contactid);
//        toast.success("Register successfully", {
//          position: "top-right",
//          autoClose: 5000,
//          hideProgressBar: false,
//          closeOnClick: true,
//          pauseOnHover: true,
//          draggable: true,
//          progress: undefined,
//          theme: "colored",
//        });
      } else {
        swal({
          text: "Something went wrong", // Your error message here
          button: 'OK', // Display an "OK" button
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const contact_address = (contact_id, type, contact_type) => {
    async function submitContactAdd() {
      let url = baseurl + "/expo_access_api/getContactBillingAddressForBot/";
      const res = await axios.post(url, {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        contact_id: contact_id,
        billing_type: type,
        contact_type: contact_type,
      });
      if (res.data[0].STATUS == "SUCCESS") {
        setAddress(res.data[0].DATA);
        const obj = res.data[0].DATA.find((value) => value.billing_flg == "Y");
        if (!obj) {
          setAddressID("");
          setAddnickname("");
          setAddcity("");
          setAddarea("");
          setAddaddress("");
          setAddcountry("");
          setAddzipcode("");
        } else {
          setAddressID(obj.address_id);
          setAddnickname(obj.nickname);
          setAddcity(obj.city_name);
          setAddarea(obj.area);
          setAddaddress(obj.address_1);
          setAddcountry(obj.country_name);
          setAddzipcode(obj.zipcode);
        }
      } else {
        console.log("undone");
        setAddress([]);
        setAddressID("");
      }
    }
    submitContactAdd();
  };

  const handleLogout = () => {
    // Add any additional actions you need before logging out
    window.location.reload();
    setMobileNo("");
    setAddressID("");
    setAddnickname("");
    setAddaddress("");
    setAddarea("");
    setAddcity("");
    setAddcountry("");
    setAddzipcode("");
    localStorage.setItem("showlogin", true);
    localStorage.removeItem("showdashboard");
    localStorage.removeItem("contactid");
    localStorage.removeItem("contacttype");
    localStorage.removeItem("contactData");
    localStorage.removeItem("mobileno");
    localStorage.removeItem("companyDetails");
    localStorage.removeItem("showotp");
    localStorage.removeItem("showregister");
  };

  //footer-link
  const handleLinkClick = () => {
    window.open(`https://hofficecrm.com/`);
  };
  const handleLinkClickmyExhi = () => {
    window.open(`https://www.myexhi.com/`);
  };
  if (localStorageValue === 'false') {
    navigate("/soulmatesearch");
    return false;
  }
  return (
    <>
      {/* <Button onClick={toPDF}>Download PDF</Button> */}
      {/* <Document>
      <Page> */}

      <div
        style={{
          fontFamily: "'Poppins', sans-serif",
          overflowX: "hidden",
        }}
        // ref={targetRef}
      >
        {isMobile ? (
          <div className="">
            {showlogin || showotp || showregister ? (
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    <img
                      className=""
                      src={leftlogo}
                      alt="left_logo"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <div>
                    <img
                      className=""
                      src={rightlogo}
                      alt="right_logo"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                </div>
                <div className="text-center py-3">
                  <h1
                    className="text-center red-header"
                    style={{ fontSize: "25px !important" }}
                  >
                    SOULMATE CONNECT
                  </h1>
                </div>
              </>
            ) : null}

            <Container className="extra-space">
              {/* showlogin  */}
              {showlogin && (
                <Card className="main-card">
                  <Container fluid>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src={`${baseurl}/public/images/orderbot/mobile_icon.png`}
                            className="img-fluid my-4"
                            alt="..."
                            height="90"
                            width="170"
                          />
                        </div>
                        <h1 className="login-header text-center">
                          Login with Mobile Number
                        </h1>
                        <p className="text-center login-para mt-1">
                          Enter your mobile number. We will
                          <br /> sent you OTP on WhatsApp / <br /> Registered
                          Email Address to verify <br /> your account.
                        </p>
                        <Form onSubmit={async (e) => {
                              e.preventDefault();
                              // setOtpBtnDisable(false);
                              localStorage.setItem("mobileno", mobileNo);
                              handleCheckRecordOtp(e);
                            }}>
                        <div className="d-flex justify-content-center align-items-center">
                          <Row>
                            <Col xs={3} md={3} style={{ paddingRight: "0px" }}>
                              <Form.Select
                                className="register-full input-register mb-3"
                                id="country"
                                value={selectedcode}
                                onChange={(e) => {
                                  setSelectedcode(e.target.value);
                                  setMobileNo("");
                                }}
                                required
                              >
                                {countrycode.map((value, index) => (
                                  <option key={index}>+{value}</option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col xs={9} md={9}>
                          <Form.Control
                            placeholder="Mobile number"
                                className="input-register register-full"
                                value={mobileNo}
                                onChange={(e) => {
                                  const inputMobileNo = e.target.value;
                                  if (selectedcode == "+91") {
                                    if (
                                      /^\d*$/.test(inputMobileNo) &&
                                      inputMobileNo.length <= 10
                                    ) {
                                      setMobileNo(inputMobileNo);
                                    }
                                  } else {
                                    console.log(
                                      selectedcode + mobileNo + "else conditon"
                                    );
                                    // For other codes, set mobileNo without validation
                                    if (/^\d*$/.test(inputMobileNo)) {
                                      setMobileNo(inputMobileNo);
                                    }
                                  }
                                }}
                                pattern="[0-9]{10}"
                                inputMode="numeric"
                              />
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="d-flex justify-content-center align-items-center">
                          <Form.Control
                            placeholder="Mobile number"
                            className="mt-4 input-register register-full"
                            value={mobileNo}
                            onChange={(e) => {
                              const inputMobileNo = e.target.value;

                              if (
                                /^\d*$/.test(inputMobileNo) &&
                                inputMobileNo.length <= 10
                              ) {
                                setMobileNo(inputMobileNo);
                              }
                            }}
                            pattern="[0-9]{10}"
                            inputMode="numeric"
                            style={{ width: "300px" }}
                          />
                        </div> */}
                        <div className="text-center pt-4 pb-4">
                          <Button
                            className="login-btn"
                            type="submit"
                            // onClick={async (e) => {
                            //   e.preventDefault();
                            //   // setOtpBtnDisable(false);
                            //   localStorage.setItem("mobileno", mobileNo);
                            //   handleCheckRecordOtp(e);
                            // }}
                            disabled={
                              selectedcode != "+91"
                                ? false
                                : continuebtn || mobileNo == null || mobileNo.length != 10
                            }
                          >
                            Continue
                          </Button>
                        </div>
                        </Form>
                        <div className="d-flex justify-content-center align-items-center my-2">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon.png`}
                            className="me-1"
                            alt="changenum"
                          />
                          <a
                            className="change-mobile"
                            onClick={() => {
                              setShowregister(true);
                              setShowlogin(false);
                            }}
                          >
                            Not a Registered user?
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              )}

              {/* showotp */}
              {showotp && (
                <Card className="main-card">
                  <Container fluid>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="text-center">
                          <img
                            src={`${baseurl}/public/images/orderbot/mobile_icon_verify.png`}
                            className="img-fluid my-4"
                            alt="..."
                            height="90"
                            width="170"
                          />
                        </div>
                        <h1 className="login-header text-center">
                          Verify your number by OTP
                        </h1>
                        <p className="text-center login-para mt-1">
                          Enter OTP Code sent to your mobile <br /> number {selectedcode}{" "}
                          {mobileNo}
                        </p>
                        <Form onSubmit={(e) => {
                          e.preventDefault()
                                  setShowlogin(false);
                                  handleVerifyMobileOtp();
                                }}>
                        <div className="d-flex justify-content-center align-items-center mt-4 mx-2">
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            maxLength={6} // Set the maximum length to 6 digits
                            renderSeparator={
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            }
                            renderInput={(props) => (
                              <>
                                {console.log(props + "propsinput")}
                                <input
                                  {...props}
                                  type="text" // Use type="text" to allow custom validation
                                  pattern="[0-9]*" // Use a regular expression to only allow numeric input
                                  inputMode="numeric"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Remove non-numeric characters
                                  }}
                                  style={{
                                    width: "42px", // Add your custom width
                                    padding: "4px",
                                    textAlign: "center",
                                    color: "#212529",
                                    backgroundColor: "#fff",
                                    borderColor: "#71d191",
                                    border: "3px solid #b6d8c5",
                                    outline: "0",
                                    boxShadow: "0 0 0 0.0rem #b6d8c5",
                                    borderRadius: "4px",
                                    fontSize: "22px",
                                    fontWeight: "bold",
                                    display: "block",
                                    height: "40px",
                                  }}
                                  onFocus={(e) => {
                                    e.target.style.boxShadow =  "0 0 0 0.1rem #9addae" ; // Change border color on focus
                                    e.target.style.borderColor =  "#9addae " ; // Change border color on focus
                                  }}
                                  onBlur={(e) => {
                                    e.target.style.boxShadow =  "0 0 0 0.0rem #b6d8c5" ; // Change border color on focus
                                    e.target.style.borderColor =  "#b6d8c5 " ; // Change border color on focus
                                  }}
                                />
                              </>
                            )}
                          />
                        </div>
                        {seconds == 0 ? (
                          <>
                            <div className="text-center mt-3">
                              Oops! Your OTP has expired or is incorrect. Please
                              click the 'Resend OTP' button to receive a new
                              one.
                            </div>
                            <div className="text-center">
                              <Button
                                variant="primary"
                                className="mt-4 login-btn"
                                onClick={() => {
                                  setSeconds(120);
                                  setOtp("");
                                }}
                              >
                                Resend OTP
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-center mt-3">
                              OTP is valid for: {formattedTime} minutes.
                            </div>

                            <div className="text-center">
                              <Button
                                variant="primary"
                                type="submit"
                                className="mt-4 login-btn"
                                // onClick={() => {
                                //   setShowlogin(false);
                                //   handleVerifyMobileOtp();
                                // }}
                                disabled={otpbtndisable}
                              >
                                Verify
                              </Button>
                            </div>
                          </>
                        )}
                        </Form>
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon.png`}
                            className="me-1"
                            alt="change_num"
                          />
                          <a
                            className="change-mobile"
                            onClick={() => {
                              setShowlogin(true);
                              setShowotp(false);
                              setSeconds(120);
                              setOtp("");
                            }}
                          >
                            Change Mobile Number
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              )}

              {/* showregister  */}

              {showregister && (
                <Card className="main-card">
                  <Container fluid>
                    <Row>
                      {/* <Col xs={12} md={6} className="left-img">
                    <Card.Body className="">
                      <img src={leftimg} className="img-fluid" alt="..." />
                    </Card.Body>
                  </Col> */}
                      <Col xs={12} md={6}>
                        <div className="text-center">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon-3.png`}
                            className="img-fluid mt-4 mb-2"
                            alt="..."
                            height="90"
                            width="170"
                          />
                        </div>
                        <h4 className="text-center login-header">
                          Guest user registration & login
                        </h4>
                        <p className="text-center fw-medium">
                          It looks like you're a new user! To get started,
                          please complete your registration by providing the
                          required information.
                        </p>
                        <Form
                          className="mt-3"
                          onSubmit={handleSubmit(onsubmit)}
                        >
                          <Row className="mb-3">
                            <Form.Group as={Col} md="12">
                              {/* <Form.Label>First name</Form.Label> */}
                              <Form.Control
                                className="register-full input-register"
                                type="text"
                                name="name"
                                id="name"
                                {...register("name", {
                                  required: true,
                                })}
                                placeholder="Enter Name"
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3">
                            <Col xs={3} md={3} style={{ paddingRight: "0px" }}>
                              <Form.Select
                                className="register-full input-register"
                                id="country"
                                value={selectedcode}
                                onChange={(e) => {
                                  setSelectedcode(e.target.value);
                                  setMobileNo("");
                                }}
                                required
                              >
                                {countrycode.map((value, index) => (
                                  <option key={index}>+{value}</option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col xs={9} md={9}>
                              <Form.Control
                                placeholder="Mobile number"
                                className="input-register register-full"
                                value={mobileNo}
                                onChange={(e) => {
                                  const inputMobileNo = e.target.value;

                                  if (selectedcode == "+91") {
                                  if (
                                    /^\d*$/.test(inputMobileNo) &&
                                    inputMobileNo.length <= 10
                                  ) {
                                    setMobileNo(inputMobileNo);
                                  }
                                  } else {
                                    console.log(
                                      selectedcode + mobileNo + "else conditon"
                                    );
                                    // For other codes, set mobileNo without validation
                                    if (/^\d*$/.test(inputMobileNo)) {
                                      setMobileNo(inputMobileNo);
                                    }
                                  }
                                }}
                                pattern="[0-9]{10}"
                                inputMode="numeric"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Form.Group as={Col} md="12">
                              <Form.Control
                                className="register-full input-register"
                                type="email"
                                name="email"
                                id="email"
                                {...register("email")}
                                placeholder="Enter Email"
                              />
                              {/* <Form.Control.Feedback type="invalid">
      Please enter a valid email address.
    </Form.Control.Feedback> */}
                            </Form.Group>
                          </Row>

                          <div className="text-center">
                            <Button type="submit" className="mt-4 login-btn">
                              Register
                            </Button>
                          </div>
                        </Form>
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon.png`}
                            className="me-1"
                            alt="changenum"
                          />
                          <a
                            className="change-mobile"
                            onClick={() => {
                              setShowlogin(true);
                              setShowotp(false);
                              setSeconds(120);
                              setShowregister(false);
                              setOtp("");
                              const fieldsToReset = [
                                "name",
                                "email",
                                "pincode",
                              ];
                              fieldsToReset.forEach((field) =>
                                setValue(field, "")
                              );
                            }}
                          >
                            Change Mobile Number
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              )}
            </Container>
            {showlogin || showotp || showregister ? (
              <div className="text-center mt-4">
                <div>
                  {/* <h1 className="green-header fs-5">
                    Brought to you by :
                    <span
                      className="text-underline"
                      onClick={handleLinkClickmyExhi}
                    >
                      MyExhi
                    </span>
                  </h1> */}
                </div>
                <div>
                  <h1  style={{fontSize:"12px",color:"black !important",fontWeight:"600"}}>
                    Powered by :
                    <span className="text-underline" onClick={handleLinkClick}>
                      www.hofficecrm.com
                    </span>
                  </h1>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div
              className="text-center"
              style={{
                marginInline: "250px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              {/* {`${baseurl}/viewimage/getcompany/${companyDetails.logo_path}`} */}
              {showlogin || showotp || showregister ? (
                <div className="text-center">
                  {/* <img
                    src={`${baseurl}/viewimage/getcompany/${companyDetails.logo_path}`}
                    className="img-fluid"
                    alt="..."
                    height="100"
                    width="200"
                  /> */}
                  <h1 className="red-header" style={{fontSize:"50px"}}>SOULMATE CONNECT</h1>
                </div>
              ) : null}

              {/* Login form  */}
              {showlogin && (
                <Card className="main-card">
                  <Container fluid>
                    <Row className="img-row">
                      <Col xs={12} md={6} className="left-img-soulmate">
                        <Card.Body >
                          <img
                            src={left_marriagevector}
                            className="img-fluid"
                            alt="..."
                            // style={{
                            //   borderTopLeftRadius: "6px",
                            //   borderBottomLeftRadius: "6px",
                            //   maxWidth: "100%",
                            //   maxHeight: "100%",
                            //   height: "auto",
                            //   position: "relative",
                            //   top: "50px",
                            // }}
                          />
                        </Card.Body>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src={`${baseurl}/public/images/orderbot/mobile_icon.png`}
                            className="img-fluid my-4"
                            alt="..."
                             style={{width:"100px",height:"100px"}}
                          />
                        </div>
                        <h1 className="login-header text-center" style={{fontSize:"22px"}}>
                          Login with Mobile Number
                        </h1>
                        <p className="text-center login-para mt-1" style={{fontSize:"14px"}}>
                          Enter your mobile number. We will
                          <br /> sent you OTP on WhatsApp / <br />
                          Registered Email Address to verify <br />
                          your account.
                        </p>
                        <Form onSubmit={async (e) => {
                              e.preventDefault();
                              // setOtpBtnDisable(false);
                              localStorage.setItem("mobileno", mobileNo);
                              handleCheckRecordOtp(e);
                            }}>
                        <div className="d-flex justify-content-center align-items-center">
                          <Row>
                            <Col xs={3} md={3} style={{ paddingRight: "0px" }}>
                              <Form.Select
                                className="register-full input-register mb-3"
                                id="country"
                                value={selectedcode}
                                onChange={(e) => {
                                  setSelectedcode(e.target.value);
                                  setMobileNo("");
                                }}
                                required
                              >
                                {countrycode.map((value, index) => (
                                  <option key={index}>+{value}</option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col xs={9} md={9}>
                          <Form.Control
                            placeholder="Mobile number"
                                className="input-register register-full"
                                value={mobileNo}
                                onChange={(e) => {
                                  const inputMobileNo = e.target.value;

                                  if (selectedcode == "+91") {
                                    if (
                                      /^\d*$/.test(inputMobileNo) &&
                                      inputMobileNo.length <= 10
                                    ) {
                                      setMobileNo(inputMobileNo);
                                    }
                                  } else {
                                    console.log(
                                      selectedcode + mobileNo + "else conditon"
                                    );
                                    // For other codes, set mobileNo without validation
                                    if (/^\d*$/.test(inputMobileNo)) {
                                      setMobileNo(inputMobileNo);
                                    }
                                  }
                                }}
                                pattern="[0-9]{10}"
                                inputMode="numeric"
                                style={{padding:"6px"}}
                              />
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="d-flex justify-content-center align-items-center">
                          <Form.Select
                            className="register-full input-register mb-3"
                            id="country"
                            value={selectedcode}
                            onChange={(e) => {
                              setSelectedcode(e.target.value);
                            }}
                            required
                          >
                            {countrycode.map((value, index) => (
                              <option key={index}>{value}</option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            placeholder="Mobile number"
                            className="mt-2 input-register register-full"
                            value={mobileNo}
                            onChange={(e) => {
                              const inputMobileNo = e.target.value;

                              if (
                                /^\d*$/.test(inputMobileNo) &&
                                inputMobileNo.length <= 10
                              ) {
                                setMobileNo(inputMobileNo);
                              }
                            }}
                            pattern="[0-9]{10}"
                            inputMode="numeric"
                            style={{ width: "350px" }}
                          />
                        </div> */}
                        <div className="text-center py-2">
                          <Button
                            className="login-btn"
                            type="submit"
                            // onClick={async (e) => {
                            //   e.preventDefault();
                            //   // setOtpBtnDisable(false);
                            //   localStorage.setItem("mobileno", mobileNo);
                            //   handleCheckRecordOtp(e);
                            // }}
                            disabled={
                              selectedcode != "+91"
                                ? false
                                : continuebtn || mobileNo == null || mobileNo.length != 10
                            }
                            style={{width:"280px",height:"50px"}}
                          >
                            Continue
                          </Button>
                        </div>
                        </Form>
                        <div className="d-flex justify-content-center align-items-center my-2">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon.png`}
                            className="me-1"
                            alt="change_num"
                          />
                          <a
                            className="change-mobile"
                            onClick={() => {
                              setShowregister(true);
                              setShowlogin(false);
                            }}
                            style={{fontSize:"14px"}}
                          >
                            Not a Registered user?
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              )}

              {/* Otp form  */}
              {showotp && (
                <Card className="main-card">
                  <Container fluid>
                    <Row className="img-row">
                      <Col xs={12} md={6} className="left-img-soulmate">
                        <Card.Body >
                          <img
                            src={left_marriagevector}
                            className="img-fluid"
                            alt="..."
                            // style={{
                            //   borderTopLeftRadius: "6px",
                            //   borderBottomLeftRadius: "6px",
                            //   maxWidth: "100%",
                            //   maxHeight: "100%",
                            //   height: "auto",
                            //   position: "relative",
                            //   top: "40px",
                            // }}
                          />
                        </Card.Body>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="text-center">
                          <img
                            src={`${baseurl}/public/images/orderbot/mobile_icon_verify.png`}
                            className="img-fluid my-4"
                            alt="..."
                            style={{width:"100px",height:"100px"}}
                          />
                        </div>
                        <h1 className="login-header text-center" style={{fontSize:"22px"}}>
                          Verify your number by OTP
                        </h1>
                        <p className="text-center login-para mt-1" style={{fontSize:"14px"}}>
                          Enter OTP Code sent to your mobile <br /> number {selectedcode}{" "}
                          {mobileNo}
                        </p>
                        <Form onSubmit={(e) => {
                          e.preventDefault()
                                  setShowlogin(false);
                                  handleVerifyMobileOtp();
                                }}>
                        <div className="d-flex justify-content-center align-items-center mt-2">
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            }
                            renderInput={(props) => (
                              <>
                                {/* {console.log(props) + "otpinput"} */}
                                <input
                                  {...props}
                                  type="text" // Use type="text" to allow custom validation
                                  pattern="[0-9]*" // Use a regular expression to only allow numeric input
                                  inputMode="numeric"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Remove non-numeric characters
                                  }}
                                  style={{
                                    width: "40px", // Add your custom width
                                    padding: "4px",
                                    textAlign: "center",
                                    color: "#212529",
                                    backgroundColor: "#fff",
                                    borderColor: "#71d191",
                                    outline: "0",
                                    border: "3px solid #b6d8c5",
                                    boxShadow: "0 0 0 0.0rem #b6d8c5",
                                    borderRadius: "4px",
                                    fontSize: "22px",
                                    fontWeight: "bold",
                                    display: "block",
                                    height: "40px",
                                  }}
                                  onFocus={(e) => {
                                    e.target.style.boxShadow =  "0 0 0 0.1rem #9addae" ; // Change border color on focus
                                    e.target.style.borderColor =  "#9addae " ; // Change border color on focus
                                  }}
                                  onBlur={(e) => {
                                    e.target.style.boxShadow =  "0 0 0 0.0rem #b6d8c5" ; // Change border color on focus
                                    e.target.style.borderColor =  "#b6d8c5 " ; // Change border color on focus
                                  }}
                                />
                              </>
                            )}
                          />
                        </div>
                        {seconds == 0 ? (
                          <>
                            <div className="text-center mt-3">
                              Oops! Your OTP has expired or is incorrect. Please
                              click
                              <br />
                              the 'Resend OTP' button to receive a new one.
                            </div>
                            <div className="text-center">
                              <Button
                                variant="primary"
                                className="mt-4 login-btn"
                                onClick={() => {
                                  setSeconds(120);
                                  setOtp("");
                                }}
                              >
                                Resend OTP
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-center mt-3">
                              OTP is valid for: {formattedTime} minutes.
                            </div>

                            <div className="text-center py-1">
                              <Button
                                variant="primary"
                                type="submit"
                                className="mt-2 login-btn"
                                // onClick={() => {
                                //   setShowlogin(false);
                                //   handleVerifyMobileOtp();
                                // }}
                                disabled={otpbtndisable}
                                style={{width:"280px",height:"50px"}}
                              >
                                Verify
                              </Button>
                            </div>
                          </>
                        )}
                        </Form>
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon.png`}
                            className="me-1"
                            alt="change-num"
                          />
                          <a
                            className="change-mobile"
                            onClick={() => {
                              setShowlogin(true);
                              setShowotp(false);
                              setSeconds(120);
                              setOtp("");
                            }}
                            style={{fontSize:"14px"}}
                          >
                            Change Mobile Number
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              )}

              {/* register form  */}
              {showregister && (
                <Card className="main-card">
                  <Container fluid>
                    <Row className="img-row">
                      <Col xs={12} md={6} className="left-img-soulmate">
                        <Card.Body >
                          <img
                            src={left_marriagevector}
                            className="img-fluid"
                            alt="..."
                            // style={{
                            //   borderTopLeftRadius: "6px",
                            //   borderBottomLeftRadius: "6px",
                            //   maxWidth: "100%",
                            //   maxHeight: "100%",
                            //   height: "auto",
                            //   position: "relative",
                            //   top: "100px",
                            //   // height:"100%"
                            // }}
                          />
                        </Card.Body>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="text-center">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon-3.png`}
                            className="img-fluid mt-4 mb-2"
                            alt="..."
                            style={{width:"100px",height:"100px"}}
                          />
                        </div>
                        <h4 className="text-center login-header" style={{fontSize:"22px"}}>
                          New user registration
                        </h4>
                        <p className="text-center fw-medium" style={{fontSize:"14px"}}>
                          It looks like you're a new user! To get started,
                          <br /> please complete your registration by providing
                          <br /> the required information.
                        </p>
                        <Form
                          className="mt-3 mx-3"
                          onSubmit={handleSubmit(onsubmit)}
                        >
                          <Row className="mb-3">
                            <Form.Group as={Col} md="12">
                              {/* <Form.Label>First name</Form.Label> */}
                              <Form.Control
                                className="register-full input-register"
                                type="text"
                                name="name"
                                id="name"
                                {...register("name", {
                                  required: true,
                                })}
                                placeholder="Enter Name"
                                onKeyDown={(e) => {
                                  if (/\d/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                style={{padding:"5px"}}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3">
                            <Col xs={3} md={3} style={{ paddingRight: "0px" }}>
                              <Form.Select
                                className="register-full input-register"
                                id="country"
                                value={selectedcode}
                                onChange={(e) => {
                                  setSelectedcode(e.target.value);
                                  setMobileNo("");
                                }}
                                style={{padding:"5px"}}
                                required
                              >
                                {countrycode.map((value, index) => (
                                  <option key={index}>+{value}</option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col xs={9} md={9}>
                              <Form.Control
                                placeholder="Mobile number"
                                className="input-register register-full"
                                value={mobileNo}
                                style={{padding:"5px"}}
                                onChange={(e) => {
                                  const inputMobileNo = e.target.value;

                                  if (selectedcode == "+91") {
                                  if (
                                    /^\d*$/.test(inputMobileNo) &&
                                    inputMobileNo.length <= 10
                                  ) {
                                    setMobileNo(inputMobileNo);
                                  }
                                  } else {
                                    console.log(
                                      selectedcode + mobileNo + "else conditon"
                                    );
                                    // For other codes, set mobileNo without validation
                                    if (/^\d*$/.test(inputMobileNo)) {
                                      setMobileNo(inputMobileNo);
                                    }
                                  }
                                }}
                                pattern="[0-9]{10}"
                                inputMode="numeric"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Form.Group as={Col} md="12">
                              <Form.Control
                                className="register-full input-register"
                                type="email"
                                name="email"
                                style={{padding:"5px !important"}}
                                id="email"
                                {...register("email")}
                                placeholder="Enter Email"

                              />
                              {/* <Form.Control.Feedback type="invalid">
      Please enter a valid email address.
    </Form.Control.Feedback> */}
                            </Form.Group>
                          </Row>

                          <div className="text-center">
                            <Button
                              type="submit"
                              className="mt-2 login-btn"
                              style={{ width: "280px", height: "50px" }}
                            >
                              Register
                            </Button>
                          </div>
                        </Form>
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <img
                            src={`${baseurl}/public/images/orderbot/icon.png`}
                            className="me-1"
                            alt="change-num"
                          />
                          <a
                            className="change-mobile"
                            style={{fontSize:"14px"}}
                            onClick={() => {
                              setShowlogin(true);
                              setShowotp(false);
                              setSeconds(120);
                              setShowregister(false);
                              setOtp("");
                              const fieldsToReset = [
                                "name",
                                "email",
                                "pincode",
                              ];
                              fieldsToReset.forEach((field) =>
                                setValue(field, "")
                              );
                            }}
                          >
                            Change Mobile Number
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              )}
            </div>
            {showlogin || showotp || showregister ? (
              <>
                <div>
                  {/* <h1 className="green-header left-bottom"  style={{fontSize:"15px"}}>
                    Brought to you buy :{" "}
                    <span
                      className="text-underline"
                      onClick={handleLinkClickmyExhi}
                    >
                    MyExhi
                    </span>
                  </h1> */}
                </div>
                <div>
                  <h1 className="right-bottom" style={{fontSize:"12px",color:"black !important",fontWeight:"600"}}>
                    Powered by:{" "}
                    <span className="text-underline" onClick={handleLinkClick}>
                      www.hofficecrm.com
                    </span>
                  </h1>
                </div>
                <div>
                  <img className="top-left" src={leftlogo} alt="left_logo" />
                </div>
                <div>
                  <img className="top-right" src={rightlogo} alt="right_logo" />
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Soulmatedashboard;
