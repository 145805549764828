import React from "react";
import { Link } from "react-router-dom";
function Home(){
    const handleEnd = ({ steps, values })  =>{
        alert(`Chat handleEnd callback! Number: ${values[0]}`);
    }
    return (
        <div className="docs-example-1">
        
      </div>
    )
}

export default Home;