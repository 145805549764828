// src/components/LoadingSpinner.js

import React from 'react';
import './LoadingSpinner.css'; // You can style this component

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      {/* You can use an actual loading spinner library or CSS animations here */}
      Loading...
    </div>
  );
};

export default LoadingSpinner;