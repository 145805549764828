import phpToken from "./PhpToken";
import userDetails from "./UserDetails";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
    phpToken,
    userDetails
});

export default rootReducer;