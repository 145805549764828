import React from "react";
import leftlogo from "../images/logo_left.png";
import rightlogo from "../images/logo_right.png";
import profilephoto from "../images/profilephoto.jpg";
import "./main2.css";

const Main2 = ({ solmate_data, index }) => {
  return (
    <>
      <div
        style={{
          fontFamily: "Roboto, sans-serif",
          border: "5px solid #c53c32",
          width: "447px",
          height: "631px",
            marginBottom: index > 2 ? "0.47px" : "0px",
        }}
      >
        <div className="top-portion">
          <div>
            <img
              src={leftlogo}
              style={{ width: "50px", height: "50px" }}
              alt="left_logo"
            />
          </div>
          <div>
            <h4
              className="red-color"
              style={{ fontSize: "16px", marginTop: "18px" }}
            >
              SOULMATE &nbsp;CONNECT
            </h4>
          </div>
          <div>
            <img
              src={rightlogo}
              style={{ width: "50px", height: "50px" }}
              alt="right_logo"
            />
          </div>
        </div>

        {/* middle portion  */}
        <div className="middle-portion">
          <div className="gray-background">
            <div className="middle-left">
              <div>
                {/* {solmate_data.mobile == "9462241921" ? ( */}
                  <img
                    className="left-img-middle"
                    src={solmate_data.contact_photo_path}
                    alt="Candidate Photo"
                    //style={{ maxWidth: "100%", width: "100%" }}
                  />
                {/* ) : (
                  <img
                    className="left-img-middle"
                    src={profilephoto}
                    alt="profile_photo"
                    style={{ maxWidth: "100%", width: "100%" }}
                  />
                )} */}
              </div>
              <div className="margin-left-common">
                <h6
                  className="red-color common-header"
                  style={{ marginTop: "0.2rem" }}
                >
                  EDUCATION &nbsp;DETAILS
                </h6>
                <p className="common-para">
                  Education : {solmate_data.contact_customs[0].Fields[54].PreFilled != "" ? solmate_data.contact_customs[0].Fields[54].PreFilled : solmate_data.education}{" "}
                </p>
                <p className="common-para">
                  Qualifications (Skills) : {solmate_data.contact_customs[0].Fields[55].PreFilled != "" ?  solmate_data.contact_customs[0].Fields[55].PreFilled : solmate_data.qualifications}
                </p>
              </div>
              <hr className="seperator" />
              <div className="margin-left-common">
                <h6 className="red-color common-header">
                  CANDIDATE &nbsp;OCCUPATION
                </h6>
                <p className="common-para">
                  Occupation : {solmate_data.contact_customs[0].Fields[56].PreFilled != "" ? solmate_data.contact_customs[0].Fields[56].PreFilled : solmate_data.occupation}
                </p>
                <p className="common-para">
                {solmate_data.contact_customs[1].Fields[50].Name} :{" "}
                  {solmate_data.contact_customs[1].Fields[50].PreFilled}
                </p>
              </div>
              <hr className="seperator" />
              <div className="margin-left-common">
                <h6 className="red-color common-header">
                  PHYSICAL &nbsp;ATTRIBUTES
                </h6>
                <p className="common-para">
                {solmate_data.contact_customs[2].Fields[30].Name} :{" "}
                  {solmate_data.contact_customs[2].Fields[30].PreFilled}
                </p>
                <p className="common-para">
                {solmate_data.contact_customs[2].Fields[31].Name} :{" "}
                  {solmate_data.contact_customs[2].Fields[31].PreFilled}
                </p>
                <p className="common-para">
                {solmate_data.contact_customs[2].Fields[32].Name} :{" "}
                  {solmate_data.contact_customs[2].Fields[32].PreFilled}
                </p>
              </div>
              <hr className="seperator" />
              <div className="margin-left-common">
                <h6 className="red-color common-header">
                  SOCIAL &nbsp;ATTRIBUTES
                </h6>
                <p className="common-para">
                {solmate_data.contact_customs[3].Fields[49].Name} :{" "}
                  {solmate_data.contact_customs[3].Fields[49].PreFilled}
                </p>
                <p className="common-para">
                  Gothra : {solmate_data.gotra_name}
                </p>
              </div>
              <hr className="seperator" />
              <div className="margin-left-common">
                <h6 className="red-color common-header">{solmate_data.contact_customs[4].Fields[51].Name}</h6>
                <p className="common-para">
                 {/* {solmate_data.contact_customs[4].Fields[51].PreFilled != ""
                    ? solmate_data.contact_customs[4].Fields[51].PreFilled
                    : "--"}  */}
                {solmate_data.contact_customs[4].Fields[51].PreFilled !==
                  "" ? (
                    <>
                      {solmate_data.contact_customs[4].Fields[51].PreFilled.split(
                        ","
                      )
                        .slice(0, 4)
                        .join(", ")}
                      <br />
                      {solmate_data.contact_customs[4].Fields[51].PreFilled.split(
                        ","
                      )
                        .slice(4)
                        .join(", ")}
                    </>
                  ) : (
                    "--"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-red f-white">
              <div className="margin-left-common">
                <h6
                  className="common-header right-info-header"
                  style={{ fontWeight: "bold" }}
                >
                  {solmate_data.name}
                </h6>
                <div>
                  <div className="right-info">
                    <label className="common-para">Birth Date & Time</label>
                    <p className="common-para text-start">
                      : {solmate_data.dob}
                    </p>
                  </div>
                  <div className="right-info">
                    <label className="common-para">{solmate_data.contact_customs[5].Fields[48].Name}</label>
                    <p className="common-para text-start">
                      : {solmate_data.contact_customs[5].Fields[48].PreFilled}
                    </p>
                  </div>
                  <div className="right-info">
                    <label className="common-para">Marital Status:</label>
                    <p className="common-para text-start">
                      : {solmate_data.marital_status}
                    </p>
                  </div>
                  <div className="right-info">
                    <label className="common-para">Age</label>
                    <p className="common-para text-start">
                      : {solmate_data.age}
                    </p>
                  </div>
                  <div className="right-info">
                    <label className="common-para">Mobile</label>
                    <p className="common-para text-start">
                      {solmate_data.mobile !== null &&
                      solmate_data.mobile !== "" ? (
                        <>
                          {solmate_data.isd !== null &&
                          solmate_data.isd !== "" ? (
                            <>
                              :{" "}
                              {solmate_data.isd.startsWith("+")
                                ? solmate_data.isd
                                : `+${solmate_data.isd}`}{" "}
                              {solmate_data.mobile}
                            </>
                          ) : (
                            <>: {solmate_data.mobile}</>
                          )}
                        </>
                      ) : (
                        <>:</>
                      )}
                    </p>
                  </div>
                  <div className="right-info">
                    <label className="common-para">Whatsapp</label>
                    <p className="common-para text-start">
                      {solmate_data.whatsapp !== null &&
                      solmate_data.whatsapp !== "" ? (
                        <>
                          {solmate_data.isd !== null &&
                          solmate_data.isd !== "" ? (
                            <>
                              :{" "}
                              {solmate_data.isd.startsWith("+")
                                ? solmate_data.isd
                                : `+${solmate_data.isd}`}{" "}
                              {solmate_data.whatsapp}
                            </>
                          ) : (
                            <>: {solmate_data.whatsapp}</>
                          )}
                        </>
                      ) : (
                        <>:</>
                      )}
                    </p>
                  </div>
                  <div className="right-info">
                    <label className="common-para">Email</label>
                    <p className="common-para text-start">
                      : {solmate_data.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="margin-left-common">
              <h6
                className="red-color common-header"
                style={{ marginTop: "0.2rem" }}
              >
                CANDIDATE &nbsp;ADDRESS
              </h6>
              <p className="common-para">{solmate_data.contact_address}</p>
            </div>
            <hr className="seperator" />
            <div style={{ marginLeft: "10px" }}>
              <h6
                className="red-color common-header"
                style={{ marginTop: "0.2rem" }}
              >
                FAMILY &nbsp;DETAILS
              </h6>
              <div className="right-info">
                <label className="common-para">Father Name</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[52].PreFilled}{" "}
                  {solmate_data.contact_customs[5].Fields[33].PreFilled}{" "}
                  {solmate_data.contact_customs[5].Fields[34].PreFilled}
                </p>
              </div>
              <div className="right-info" style={{ paddingBottom: "8px" }}>
                <label className="common-para">{solmate_data.contact_customs[5].Fields[35].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[35].PreFilled}
                </p>
              </div>
              <div className="right-info">
                <label className="common-para">{solmate_data.contact_customs[5].Fields[36].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[36].PreFilled}
                </p>
              </div>{" "}
              {/* <div className="right-info">
                <label className="common-para">Father Name</label>
                <p className="common-para text-start">
                  : Late Shri Shaitanmal Jain
                </p>
              </div>{" "} */}
              <div className="right-info">
                <label className="common-para">Mother Name</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[37].PreFilled}{" "}
                  {solmate_data.contact_customs[5].Fields[38].PreFilled}{" "}
                  {solmate_data.contact_customs[5].Fields[39].PreFilled}
                </p>
              </div>{" "}
              <div className="right-info" style={{ paddingBottom: "8px" }}>
                <label className="common-para">{solmate_data.contact_customs[5].Fields[40].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[40].PreFilled}
                </p>
              </div>{" "}
              <div className="right-info">
                <label className="common-para">{solmate_data.contact_customs[5].Fields[41].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[41].PreFilled}
                </p>
              </div>{" "}
              <div className="right-info" style={{ paddingBottom: "8px" }}>
                <label className="common-para">{solmate_data.contact_customs[5].Fields[42].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[42].PreFilled}
                </p>
              </div>{" "}
              <div className="right-info" style={{ paddingBottom: "8px" }}>
                <label className="common-para">{solmate_data.contact_customs[5].Fields[35].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[35].PreFilled}
                </p>
              </div>{" "}
              <div className="right-info" style={{ paddingBottom: "8px" }}>
                <label className="common-para">{solmate_data.contact_customs[5].Fields[43].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[43].PreFilled}
                </p>
              </div>{" "}
              <div className="right-info">
                <label className="common-para">{solmate_data.contact_customs[5].Fields[44].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[44].PreFilled}
                </p>
              </div>
              <div className="right-info">
                <label className="common-para">{solmate_data.contact_customs[5].Fields[45].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[45].PreFilled}
                </p>
              </div>
              <div className="right-info">
                <label className="common-para">{solmate_data.contact_customs[5].Fields[46].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[46].PreFilled}
                </p>
              </div>{" "}
              <div className="right-info">
                <label className="common-para">{solmate_data.contact_customs[5].Fields[47].Name}</label>
                <p className="common-para text-start">
                  : {solmate_data.contact_customs[5].Fields[47].PreFilled}
                </p>
              </div>
            </div>
            <hr className="seperator" />

            <div className="margin-left-common">
              <h6
                className="red-color common-header"
                style={{ marginTop: "0.2rem" }}
              >
                {solmate_data.contact_customs[3].Fields[53].Name}
              </h6>
              <p className="common-para">
                {solmate_data.contact_customs[3].Fields[53].PreFilled != ""
                  ? solmate_data.contact_customs[3].Fields[53].PreFilled
                  : "--"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main2;
