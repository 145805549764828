import React,{ useEffect,useState } from 'react';
import { BrowserRouter as Router, Routes, Route ,Navigate  } from "react-router-dom";
import "./App.css";
import "./style.css";
import "react-datetime/css/react-datetime.css";
import Home from "./Home";
import { useSelector } from 'react-redux';
import SoulmateSearch from "./soulmate/SoulmateSearch";
import Soulmatedashboard from "./soulmate/Soulmatedashboard";
import axios from "axios";
function App() {
  
  return (
    <Router>
      <Routes>
        <Route
            exact
            path="/"
            element={<Soulmatedashboard />}
          />
          <Route
          exact
          path="/soulmatesearch"
          element={<SoulmateSearch />}
          />
          
      </Routes>
    </Router>
  );
}

export default App;
