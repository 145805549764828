import React, { useEffect, useState } from "react";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { components } from "react-select";
import Button from "react-bootstrap/Button";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Main2 from "./Main2";
import { jsPDF } from "jspdf";
import { AiOutlineLogout } from "react-icons/ai";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../Spinner/LoadingSpinner';
import swal from "sweetalert";
import rightlogo from "../images/logo_right.png";
const SoulmateSearch = () => {
  console.log("SolmateSerach");
  const localStorageValue = localStorage.getItem('showlogin');
  const contactData = JSON.parse(localStorage.getItem('contactData'));
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState([]);
  const [companyBasicConfig, setCompanyBasicConfig] = useState([]);
  const [selEducation, setEducation] = useState([]);
  const [selProfession, setProfession] = useState([]);
  const [selOccupation, setOccupation] = useState([]);
  const [soulmatarray, setSoualmatArray] = useState([]);
  const [selGautra, setGautra] = useState([]);
  const [age_from, setAgeFrom] = useState("");
  const [age_to, setAgeto] = useState("");
  const [selCountry, setCountry] = useState([]);
  const [selState, setState] = useState([]);
  const [selCity, setCity] = useState([]);
  const [education_master, setEducationMaster] = useState([]);
  const [qualification_master, setQualificationMaster] = useState([]);
  const [occupation_master, setOccupationMaster] = useState([]);
  const [gotra_master, setGotraMaster] = useState([]);
  const [country_master, setCountryMaster] = useState([]);
  const [state_master, setStateMaster] = useState([]);
  const [city_master, setCityMaster] = useState([]);
  const [rangeH, setRangeH] = useState(0);
  const [rangeE, setRangeE] = useState(0);
  const [genderValue, setGenderValue] = useState("");
  const [maritial_status, setMaterialStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  
  
  const AUTHORIZE_KEY = "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy";
  const baseurl = "https://jitosoulmate.ondemandcrm.co"; //window.location.origin;
  const patientname = localStorage.getItem("patientname");
  const total_download_count = contactData?.download_count;
  const display_contact_name = contactData?.name;
  const handleChangeAge = (value) => {
    setRangeH(value);
  };
  const formatAge = (value) => value;
  const handlechangeEducation = (e) => {
    setEducation(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handlechangeProfession = (e) => {
    setProfession(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handlechangeOccupation = (e) => {
    setOccupation(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handlechangeGautra = (e) => {
    setGautra(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  //#=========
  const handlechangeCountry = (e) => {
    setCountry(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handlechangeState = (e) => {
    setState(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handlechangeCity = (e) => {
    setCity(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  //#===========
  useEffect(() => {
    const postData = {
      AUTHORIZEKEY: AUTHORIZE_KEY,
    };
    axios
      .post(baseurl + `/expo_access_api/companydetails/`, postData)
      .then((response) => {
        if (response.data[0].STATUS == "SUCCESS") {
          setCompanyInfo(response.data[0].Companyinfo);
          setCompanyBasicConfig(response.data[0].DETAILS);
        } else {
          setCompanyInfo([]);
          setCompanyBasicConfig([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setCompanyInfo([]);
        setCompanyBasicConfig([]);
      });
  }, []);
  useEffect(() => {
    const postData = {
      AUTHORIZEKEY: AUTHORIZE_KEY,
    };
    axios
      .post(baseurl + `/expo_access_api/soulmateBasicdetails/`, postData)
      .then((response) => {
        if (response.data[0].STATUS == "SUCCESS") {
          console.log(response.data[0].DETAILS.education_data);
          setQualificationMaster(response.data[0].DETAILS.qualification_data);
          setGotraMaster(response.data[0].DETAILS.gotra_data);
        } else {
          setQualificationMaster([]);
          setGotraMaster([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setQualificationMaster([]);
        setGotraMaster([]);
      });
  }, []);
  useEffect(() => {
    const postData = {
      AUTHORIZEKEY: AUTHORIZE_KEY,
    };
    axios
      .post(baseurl + `/expo_access_api/soulmateEducationData/`, postData)
      .then((response) => {
        if (response.data[0].STATUS == "SUCCESS") {
          console.log(response.data[0].DETAILS.education_data);
          //setEducationMaster(response.data[0].DETAILS.education_data);
          setOccupationMaster(response.data[0].DETAILS.occupation_data);
        } else {
          setEducationMaster([]);
          setOccupationMaster([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setEducationMaster([]);
        setOccupationMaster([]);
      });
  }, []);
  useEffect(() => {
    const postData = {
      AUTHORIZEKEY: AUTHORIZE_KEY,
    };
    axios
      .post(
        baseurl + `/expo_access_api/soulmateContactAddressMaster/`,
        postData
      )
      .then((response) => {
        if (response.data[0].STATUS == "SUCCESS") {
          setCountryMaster(response.data[0].DETAILS.cntry_array);
          setStateMaster(response.data[0].DETAILS.state_array);
          setCityMaster(response.data[0].DETAILS.city_array);
        } else {
          setCountryMaster([]);
          setStateMaster([]);
          setCityMaster([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setCountryMaster([]);
        setStateMaster([]);
        setCityMaster([]);
      });
  }, []);
  const handleSubmit = () => {
    if(genderValue == ""){
      swal("Please select Looking for first!");
      return false;
    }
    if(maritial_status == ""){
      swal("Please select Maritial status first!");
      return false;
    }
    if(age_from == "" || age_to == ""){
      swal("Please select Age From to To first!");
      return false;
    }
    setLoading(true);
    const postData = {
      AUTHORIZEKEY: AUTHORIZE_KEY,
      gender: genderValue,
      merrage_status: maritial_status,
      agefrom: age_from,
      ageto: age_to,
      education: selEducation,
      qualification: selProfession,
      occupation: selOccupation,
      gotras: selGautra,
      country: selCountry,
      state: selState,
      city: selCity,
    };
    axios
      .post(baseurl + `/expo_access_api/getContactList/`, postData)
      .then((response) => {
        if (response.data[0].STATUS == "SUCCESS") {
          setSoualmatArray(response.data[0].DATA);
          setLoading(false);
        } else {
          setSoualmatArray([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSoualmatArray([]);
        setLoading(false);
      });
  };
  const handleKeyPress = (event) => {
    // Get the key code of the pressed key
    const keyCode = event.keyCode || event.which;

    // Check if the key is a numeric digit (0-9) or a control key like Backspace
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault(); // Prevent the input of non-numeric characters
    }
  };
  const generatePDF = () => {
    const contact_name_pdf = contactData?.name.replace(/ /g, "_");
    const contact_mobile_pdf = contactData?.mobile_no;
    const cr_date = currentDate.toLocaleString().replace(/ /g, "_");
    // Show the loading indicator
      const loadingIndicator = document.getElementById("loadingIndicator");
      loadingIndicator.style.display = "block";

      var rep = new jsPDF("p", "px", "a4");
      rep.html(document.querySelector("#rep1"), {
        callback: function (pdf) {
          // Hide the loading indicator
          loadingIndicator.style.display = "none";
          
          // Generate and save the PDF
          pdf.save(`Jito_Soulmate_Search_for_${contact_name_pdf}_${cr_date}.pdf`);
          console.log("done");
          const contact_id = contactData?.contact_id;
            const contact_type = contactData?.recordtype;
            const postData = {
              AUTHORIZEKEY: AUTHORIZE_KEY,
              contact_id:contact_id,
              contact_type:contact_type,
            };
            axios
            .post(baseurl + `/expo_access_api/soulmateContactWiseDownload/`, postData)
            .then((response) => {
              if (response.data[0].STATUS == "SUCCESS"){
                handleLogOut();
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        },
      });
    //#=============== Download Logic
    /**/
  };
  const handleLogOut = () =>{
    localStorage.setItem("showlogin", true);
    localStorage.removeItem("showdashboard");
    localStorage.removeItem("contactid");
    localStorage.removeItem("contacttype");
    localStorage.removeItem("contactData");
    localStorage.removeItem("mobileno");
    localStorage.removeItem("companyDetails");
    localStorage.removeItem("showotp");
    localStorage.removeItem("showregister");
    navigate("/");
  }
  if (localStorageValue === 'true') {
    localStorage.setItem("showlogin", true);
    localStorage.removeItem("showdashboard");
    localStorage.removeItem("contactid");
    localStorage.removeItem("contacttype");
    localStorage.removeItem("contactData");
    localStorage.removeItem("mobileno");
    localStorage.removeItem("companyDetails");
    localStorage.removeItem("showotp");
    localStorage.removeItem("showregister");
    navigate("/");
    return false;
  }
  return (
    <>
      <div className="soulmate-search-page">
        <div className="header-section">
          <div className="py-3 fl-left">
            <img
              src="https://hoffice.corpteaser.net/wp-content/uploads/2023/09/logo-1.png"
              className="img-fluid"
              alt="..."
              height="100"
              width="150"
            />
          </div>

          <div className="text-center py-3 center-content">
            <img src="https://hoffice.corpteaser.net/wp-content/uploads/2023/09/Untitled-design-16.png" />
            <h4>Welcome {display_contact_name}</h4>
            <h6>
              Apply your soulmate search criteria below to get the Bio-Data's
            </h6>
          </div>

          <div className="text-right py-3 fl-right">
            <img
              src={rightlogo}
              className="img-fluid"
              alt="..."
              height="100"
              width="150"
            />{" "}
          </div>
        </div>
        <div className="text-center py-3 center-content-mobile">
          <img src="https://hoffice.corpteaser.net/wp-content/uploads/2023/09/Untitled-design-16.png" />
          <h4>Welcome {display_contact_name}</h4>
          <h6>
            Apply your soulmate search criteria below to get the Bio-Data's
          </h6>
        </div>
        <div className="logout-section" style={{marginTop:"-20px"}}>
          <Button variant="primary" className="logout-button" onClick={handleLogOut}>
            <AiOutlineLogout />
            Logout
          </Button>{" "}
        </div>
        <div
          className="main-section-soulmate"
          style={{ padding: "0 100px 100px" }}
        >
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Search Candidate</Accordion.Header>
              <Accordion.Body>
                <Form.Group
                  className="mb-3"
                  //controlId="exampleForm.ControlInput1"
                >
                  <Form.Label><span style={{color:"red"}}>*</span>Looking for: </Form.Label>
                  <Form.Check
                    inline
                    label="Groom"
                    value="1"
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={genderValue === "1"}
                    onChange={(e) => setGenderValue(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Bride"
                    value="2"
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={genderValue === "2"}
                    onChange={(e) => setGenderValue(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  //controlId="exampleForm.ControlInput1"
                >
                  <Form.Label><span style={{color:"red"}}>*</span>Maritial status : </Form.Label>
                  <Form.Check
                    inline
                    label="Unmarried"
                    name="maritial_status"
                    value="1"
                    type="radio"
                    id={`maritial-status-radio-1`}
                    checked={maritial_status === "1"}
                    onChange={(e) => setMaterialStatus(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Widowed"
                    value="2"
                    name="maritial_status"
                    type="radio"
                    id={`maritial-status-radio-2`}
                    checked={maritial_status === "2"}
                    onChange={(e) => setMaterialStatus(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Divorced"
                    name="maritial_status"
                    value="3"
                    type="radio"
                    id={`maritial-status-radio-3`}
                    checked={maritial_status === "3"}
                    onChange={(e) => setMaterialStatus(e.target.value)}
                  />
                  {/* <Form.Check
                    inline
                    label="Divorcee"
                    name="maritial_status"
                    value="4"
                    type="radio"
                    id={`maritial-status-radio-4`}
                    checked={maritial_status === "4"}
                    onChange={(e) => setMaterialStatus(e.target.value)}
                  /> */}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  //controlId="exampleForm.ControlInput3"
                >
                  <Form.Label><span style={{color:"red"}}>*</span>Age :</Form.Label>
                  <div className="age-field">
                    {/* <Form.Label>From</Form.Label> */}
                    <Form.Control
                      placeholder="From"
                      className="form-control"
                      id="agefrom"
                      onKeyPress={handleKeyPress}
                      type="text"
                      name="age_from"
                      value={age_from}
                      onChange={(e) => setAgeFrom(e.target.value)}
                    />
                    {"  "}
                    {/* <Form.Label>To</Form.Label> */}
                    <Form.Control
                      placeholder="To"
                      onKeyPress={handleKeyPress}
                      type="text"
                      className="form-control"
                      id="ageto"
                      name="age_to"
                      value={age_to}
                      onChange={(e) => setAgeto(e.target.value)}
                    />
                  </div>
                </Form.Group>
                
                <Form.Group
                  className="mb-3"
                  //controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Qualification</Form.Label>
                  <div className="input-group">
                    <Select
                      options={qualification_master}
                      onChange={handlechangeProfession}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      // }}
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3" //controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Occupation</Form.Label>
                  <div className="input-group">
                    <Select
                      options={occupation_master}
                      onChange={handlechangeOccupation}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      // }}
                    />
                  </div>
                </Form.Group>

                {/* ========== */}
                <Form.Group
                  className="mb-3"
                  //controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Country</Form.Label>
                  <div className="input-group">
                    <Select
                      options={country_master}
                      onChange={handlechangeCountry}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      // }}
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3" //controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>State</Form.Label>
                  <div className="input-group">
                    <Select
                      options={state_master}
                      onChange={handlechangeState}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      // }}
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3" //controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>City Name</Form.Label>
                  <div className="input-group">
                    <Select
                      options={city_master}
                      onChange={handlechangeCity}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      // }}
                    />
                  </div>
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  //controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Gothra</Form.Label>
                  <div className="input-group">
                    <Select
                      options={gotra_master}
                      onChange={handlechangeGautra}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      // }}
                    />
                  </div>
                </Form.Group> */}
                {/* <Form.Group className="mb-3"></Form.Group> */}
                <Form.Group className="mb-4">
                  <Button size="md" variant="primary" onClick={handleSubmit}>
                    Search
                  </Button>{" "}
                  <Button size="md" variant="dark" onClick={()=>window.location.reload()}>
                    Reset
                  </Button>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="person-personal-details">
            {loading == true ? <LoadingSpinner /> : (
            <>
            <div id="loadingIndicator" style={{display:"none",textAlign:"right",fontSize:"16px"}}>
              Generating PDF... Please wait.
            </div>

            <div className="download-section">
              <div className="search-count-section">
                <h5>
                  <span>{soulmatarray.length}</span> search results found
                </h5>
              </div>
              {soulmatarray.length > 0 &&
              <div className="down-button-section">
                <Button
                  variant="primary"
                  className="download-button"
                  onClick={generatePDF}
                >
                  <HiOutlineDocumentDownload />
                  Download Searchfield Bio-Data's & Logout
                </Button>{" "}
                <h6>({(3 - total_download_count)} download left in your account)</h6>
              </div>
              }
            </div>
            {soulmatarray.length > 0 &&
              soulmatarray.map((item, index) => (
                <div className="person-search card">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-body">
                        <a>
                          {" "}
                          {/* Replace with the actual URL */}
                          {/* {item.mobile == "9462241921" ? ( */}
                            <img
                              src={item.contact_photo_path} // Replace with the URL of your dummy image
                              alt=""
                            />
                          {/* ) : (
                            <img
                              src="https://www.guamhomesforsaleandrent.com/sites/default/files/team5.jpg" // Replace with the URL of your dummy image
                              alt="Dummy Image"
                            />
                          )} */}
                        </a>
                      </div>
                    </div>
                    <div className="card-body-details">
                      <h4>{item.name}</h4>
                      <div className="details-person">
                        <ul>
                          <li>
                            <span className="li-lable-details">Age:</span>{" "}
                            {item.age}
                          </li>
                          <li>
                            <span className="li-lable-details">City:</span>{" "}
                            {item.city_name}
                          </li>
                          <li>
                            <span className="li-lable-details">
                              Place of Birth:
                            </span>{" "}
                            {item.contact_customs[5].Fields[48].PreFilled}
                          </li>
                          <li>
                            <span className="li-lable-details">
                              Marital Status:
                            </span>{" "}
                            {item.marital_status}
                          </li>
                          
                        </ul>
                        <ul>
                        <li>
                            <span className="li-lable-details">{item.contact_customs[2].Fields[30].Name}:</span>{" "}
                            {item.contact_customs[2].Fields[30].PreFilled}
                          </li>
                          <li>
                            <span className="li-lable-details">{item.contact_customs[2].Fields[31].Name}:</span>{" "}
                            {item.contact_customs[2].Fields[31].PreFilled}
                          </li>
                          <li>
                            <span className="li-lable-details">Education:</span>{" "}
                            {item.education}
                          </li>
                          <li>
                            <span className="li-lable-details">
                              Qualification(Skills):
                            </span>{" "}
                            {item.qualifications}
                          </li>
                          </ul>
                          <ul>
                          <li>
                            <span className="li-lable-details">
                              Occupation:
                            </span>{" "}
                            {item.occupation}
                          </li>
                          <li>
                            <span className="li-lable-details">
                            {item.contact_customs[1].Fields[50].Name}:
                            </span>{" "}
                            {item.contact_customs[1].Fields[50].PreFilled}
                          </li>
                          <li>
                            <span className="li-lable-details">{item.contact_customs[3].Fields[49].Name}:</span>{" "}
                            {item.contact_customs[3].Fields[49].PreFilled}
                          </li>
                          <li>
                            <span className="li-lable-details">Exclude Gothra:</span>{" "}
                            {item.gotra_name}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>)}
            {/*========  PDF Start ========= */}
            <div id="main" 
              style={{ display: "none", height: 0 }}
            >
              <div id="rep1">
                {soulmatarray.length > 0 &&
                  soulmatarray.map((solmate, index) => (
                    <Main2 solmate_data={solmate} index={index} />
                  ))}
              </div>
            </div>
            {/* <button onClick={generatePDF}>Click</button> */}
            {/*========  PDF End ========= */}
          </div>
        </div>
        <div className="soulmate-footer">
          <div className="footer-left-section">
            {/* <h6>
              Brought to you by:
              <a href="https://www.myexhi.com/" target="_blank">
                MyExhi
              </a>
            </h6> */}
          </div>
          <div className="footer-right-section">
            <h6 style={{fontSize:"12px",color:"black !important",fontWeight:"600"}}> 
              Powered by:{" "}
              <a style={{color:"black"}} href="https://hofficecrm.com/" target="_blank">
               www.hofficecrm.com
              </a>
            </h6>
          </div>
        </div>
        <style>
          {`
        .soulmate-search-page, .center-content-mobile{
        background: #f5f5f5;
      }
      .main-section-soulmate, .header-section, .soulmate-search-page .soulmate-footer, .logout-section {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 100px;
      }
      .header-section img {
        width: auto;
      }
      .header-section .center-content img {
        width: 150px;
      }
      .text-right.fl-right, .person-personal-details .download-section .down-button-section, .soulmate-search-page .soulmate-footer .footer-right-section {
        text-align: right !important;
      }
      .person-personal-details .download-section .search-count-section, .person-personal-details .download-section .down-button-section, .soulmate-search-page .soulmate-footer .footer-left-section, .soulmate-search-page .soulmate-footer .footer-right-section {
        width: 50%;
        display: inline-block;
      }
      .person-personal-details .download-section {
        display: inline-block;
        width: 100%;
      }
      .header-section .fl-left, .center-content, .fl-right {
        width: 33%;
        float: left;
        margin: 0px auto 10px;
      }
      .accordion-body .mb-3{
        width: 33%;
        display: inline-block;
      }
      .main-section-soulmate .mb-3 .basic-multi-select {
        width: 90% !important;
      }
      .main-section-soulmate .accordion-button {
        pointer-events: none;
      }
      .age-field, .header-section {
        display: flex;
      } 
      .accordion-button:not(.collapsed) {
        color: #fff;
        background-color: #009933;
      } 
      .accordion-body label {
        display: block;
        margin: 0 auto 5px;
        font-weight: 600;
      }
      .accordion-body .form-check-label {
        font-weight: 500 !important;
      }
      .age-field input {
        border-radius: 5px !important;
        background: #fff !important;
        margin: 0 15px 0 0;
        width: 100px;
      }
      .person-personal-details {
        margin-top: 30px;
      }
      .person-personal-details .person-search.card {
        padding: 20px;
        margin-bottom: 20px;
        border: none;
        transition: all 0.3s ease;
        letter-spacing: 0.5px;
        border-radius: 15px !important;
        box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
      }
      .media-body img {
        width: 150px;
        float: left;
        margin-right: 25px;
        border-radius: 50%;
        height: 150px;
      }
      .accordion-body .btn.btn-primary  {
        background: #093;
        padding: 5px 35px 10px 35px;
        font-size: 18px;
        font-weight: 600;
        border: 0;
        margin: 15px auto;
      }
      .accordion-body .btn.btn-dark  {
        background: #000;
        padding: 5px 35px 10px 35px;
        font-size: 18px;
        font-weight: 600;
        border: 0;
        margin: 15px auto;
      }
      .details-person ul {
        float: left;
        display: block;
        width: 25%;
        padding: 0 !important;
        margin: 0 10px;
      }
      .details-person li {
        text-align: left;
        line-height: 24px;
        padding: 0 0 15px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      .details-person .li-lable-details {
        
        display: inline-block;
        font-weight: normal;
        text-align: left;
      }
      .person-search .card-body {
        margin: 0;
        padding: 0;
      }
      .card-body-details h4, .header-section h4 {
        color: #005E32;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .header-section .text-center.py-3.center-content {
        display: block;
      }
      .text-center.py-3.center-content-mobile, .main-section-soulmate .accordion-button::after {
        display: none;
      }
      .person-personal-details .search-count-section h5 {
        color: #FFB100;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
      }
      .person-personal-details .down-button-section button, .person-personal-details .down-button-section button:hover {
        background-color: #FFB100 !important;
        border: 0;
        font-size: 18px;
        margin: 10px auto;
        font-weight: 600;
        color: #9F180E;
      }
      .person-personal-details .down-button-section h6 {
        color: #FF5959;
        font-size: 18px;
        font-weight: 600;
      }
      .footer-left-section h6, .footer-left-section a {
        color: #005E32;
        font-weight: 600;
        font-size: 18px;
      }
      .person-personal-details .download-button svg {
        height: 24px;
        margin-right: 5px;
        position: relative;
        bottom: 3px;
        width: 24px;
      }
      .soulmate-search-page .logout-section {
        text-align: right;
        margin: 10px auto;
      }
      .logout-button svg {
       position: relative;
          bottom: 1px;
          width: 24px;
          height: 24px;
          right: 5px;
          }
          .soulmate-search-page .logout-section {
          text-align: right;
          margin: 10px auto;
          }
          .logout-section .logout-button, .logout-section .logout-button:hover {
          background: red !important;
          border: 0 !important;
          font-size: 18px !important;
          }
          @keyframes blink {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0.3;
            }
            100% {
              opacity: 1;
            }
          }
          
          .blinking-button {
            animation: blink 2s infinite; /* Adjust the duration as needed */
          }
      @media only screen and (max-width: 747px) {
        .soulmate-search-page .logout-section {
          padding-right: 20px;
        }
        .main-section-soulmate {
            padding: 0 10px !important;
          }
          .main-section-soulmate, .header-section, .soulmate-search-page .soulmate-footer, .logout-section {
            max-width: 100%;
            margin: 0 auto;
            padding: 0;
            width: 100%;
          }
          .accordion-body .mb-3, .soulmate-search-page .soulmate-footer .footer-left-section, .soulmate-search-page .soulmate-footer .footer-right-section {
            width: 100%;
            float: none;
          }
          .age-field .form-label {
            padding: 0 7px 0 0px;
          }
          .details-person ul {
            float: none;
            width: 100%;
          }
          .details-person .li-lable-details {
            width: auto;
            display: inline-block;
          }
          .card-body-details h4, .header-section h4 {
            margin: 10px auto;
            text-align: center;
            width: 100%;
            display: block;
          }
          .accordion-body .mb-3, .header-section .fl-left, .center-content, .fl-right {
            width: 100%;
            float: none;
            margin: 0px auto 10px;
          }
          .media-body img {
            margin: 0 auto;
            float: none;
          }
          .person-search.card .media-body {
            text-align: center;
          }
          .card-body-details h4 {
            text-align: center;
            margin: 15px auto;
          }
          .header-section .text-center.py-3.center-content, .center-content-mobile img {
            display: none;
          }
          .text-center.py-3.center-content-mobile {
            display: block;
            padding: 0 10px !important;
            margin-bottom: 20px;
          }
          .fl-right .img-fluid {
            text-align: right;
            float: right;
            margin: 0 15px;
          }
          .header-section .img-fluid, .download-section .search-count-section h5 span {
            width: auto !important;
          }
          .center-content-mobile h4 {
            color: #005E32;
            font-size: 18px;
            font-weight: 600;
          }
          .person-personal-details .download-section .search-count-section, .person-personal-details .download-section .down-button-section {
            width: 100%;
            display: inline-block;
          }
          .person-personal-details .search-count-section h5 {
            width: auto !important;
          }
          .person-personal-details .down-button-section button {
            font-size: 14px;
          }
          .person-personal-details .download-section .down-button-section, .person-personal-details .download-section .search-count-section, .soulmate-search-page .soulmate-footer .footer-right-section, .soulmate-search-page .soulmate-footer .footer-left-section {
            text-align: center !important;
          }
          .soulmate-search-page .soulmate-footer {
            padding-bottom: 10px !important;
          }
          .person-personal-details .download-button svg {
            position: relative;
            bottom: 0px;
          }
      }
       `}
        </style>
      </div>
    </>
  );
};
export default SoulmateSearch;
